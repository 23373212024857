import './App.css';
import { useEffect, useState } from 'react';
import { Avatar, List, Carousel ,Input, Button,Image as XImage } from 'antd';
import axios from 'axios'
const { Search } = Input;
const ODKMUSIC_GATEWAY="https://video.oldking.club/odkmusic"

function App() {
  const [data,setData]=useState([])
  const [loading,setLoading]=useState(false)
  const [ipfsgateway,setIpfsgateway] = useState("http://127.0.0.1:8080")
  function checkNodeStatus(nodeUrl) {
    console.log(nodeUrl,"测试中。。。")
    return new Promise((resolve,reject)=>{
      var img = new Image();
      img.onload = function() {
          console.log(img.src,"成功")
          return resolve(true)
      };
      img.onerror = function() {
        console.log(img.src,"失败")
          return reject(false);
      };
      // Add a timestamp to avoid caching
      img.src = nodeUrl + "?t="+new Date().getTime()
    })
  }
  const searchTracks = (q)=>{
    setLoading(true)
    axios.request({
      data: {q:q.toUpperCase(),limit:100},
      method: 'POST',
      url: `${ODKMUSIC_GATEWAY}/gettracks`
    }).then(result=>{
      const data = result.data
      if (data.code===200){
        setData(data.data)
      }
      setLoading(false)
    }).catch(err=>{
        console.log(err)
        setLoading(false)
    })
  }
  useEffect(()=>{
    searchTracks("周杰伦")
    const nodeUrl = "http://127.0.0.1:8080/ipfs/QmQt6AxVLTwejiLWBHzRHatCkEsszMkEM1YRG1fA27tYSr"
    checkNodeStatus(nodeUrl).then(result=>{
      setIpfsgateway("http://127.0.0.1:8080")
    }).catch(err=>{

    })
  },[])
  return (
    <div style={{maxWidth:600,margin:'auto'}}>
      <Carousel >
    <div>
      <XImage preview={false} onClick={()=>{window.open("https://video.oldking.club/ipns/odkmusic.oldking.club")}} src='https://video.oldking.club/ipfs/QmQt6AxVLTwejiLWBHzRHatCkEsszMkEM1YRG1fA27tYSr'/>
    </div>

  </Carousel>
    <List
    itemLayout="vertical"
    size="large"
    dataSource={data}
    header={
      <Search placeholder="歌名或歌手" enterButton="搜索" size="large" loading={loading} onSearch={(v)=>{
        searchTracks(v)
      }} />
    }
    renderItem={(item,index) => <List.Item 
      key={index}
      actions={[
      <Button onClick={()=>{window.open(item.url.replaceAll("http://127.0.0.1:8080",ipfsgateway))}}>下载</Button>,
      <Button onClick={()=>{window.open(item.lyric_url.replaceAll("http://127.0.0.1:8080",ipfsgateway))}}>歌词</Button>,
      <Button onClick={()=>{window.open(item.img_url.replaceAll("http://127.0.0.1:8080",ipfsgateway))}}>封面</Button>
    ]}
    >
      <List.Item.Meta
      avatar={<Avatar src={item.img_url} size='large'/>}
      title={`${item.artist}-${item.title}${item.ext}`}
      description={`专辑:${item.album} 大小:${(item.size/1024/1024).toFixed(2)}Mb`}
      ></List.Item.Meta>
      </List.Item>}
  />
  </div>
  );
}

export default App;
